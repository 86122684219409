import React, { useState } from 'react';
import axios from 'axios';
import { getToken, getEditDeviceData, getRole, removeUserSession } from './Utils/Common';
import HandleRejection from './components/RoleRejection';

function Device(props) {
  const { params } = props.match;
  const role = getRole();
  const unit_id = params.unit_id;
  const token = getToken();
  console.log('params:', params);
  var create = true;
  var inDeviceId = '';
  var inAltId = '';
  var inSysFW = '';
  var inActive = true;
  if (unit_id !== undefined) {
    console.log('in update instead of create');
    create = false;
    const devData = getEditDeviceData();
    console.log('devData:', devData);
    inDeviceId = devData.id;
    inAltId = devData.altid;
    inSysFW = devData.fw;
    inActive = devData.active;
  }
  const device_id = useFormInput(inDeviceId);
  const device_altid = useFormInput(inAltId);
  const device_sysfw = useFormInput(inSysFW);
  const device_active = useSelectInput(inActive);
  const [updating, setUpdating] = useState(false);
  const [error, setError] = useState(null);

  const config = {
      headers: { 'Content-type': 'application/json',
                 'Authorization': `Bearer ${token}` }
   };


  // handle button click of user form
  const handleUpdate = () => {
    console.log('in handleUpdate');
    setError(null);
    setUpdating(true);
    if (create) {
      var url = '/api/v1/devices';
      var data = { unit_id: device_id.value,
                   alt_id: device_altid.value,
                   sys_fw: device_sysfw.value,
                   active: device_active.value };
      axios.post(url, data , config).then(response => {
        console.log('in post response');
        setUpdating(true);
        props.history.push('/devices');
      }).catch(error => {
        console.log('error');
        setUpdating(false);

        if (error.response.status === 401) {
          removeUserSession();
          window.location.reload();
        }
        else {
          setError((error.response.data && error.response.data.message) || "Something went wrong. Please try again later.");
        }
      });
    } else {
      var url = '/api/v1/device/' + unit_id;
      var data = { unit_id: device_id.value,
                   alt_id: device_altid.value,
                   sys_fw: device_sysfw.value,
                   active: device_active.value };
      axios.put(url, data , config).then(response => {
        console.log('in put response');
        setUpdating(true);
        props.history.push('/devices');
      }).catch(error => {
        console.log('error');
        setUpdating(false);
        if (error.response.status === 401) {
          removeUserSession();
          window.location.reload();
        }
        else {
          setError((error.response.data && error.response.data.message) || "Something went wrong. Please try again later.");
        }
      });
    }
  }

  if(role !== "6") {
    return (
      <div>
        {create?'Create':'Update'} Device Form<br /><br />
        <div>
          Device ID<br />
          <input type="text" {...device_id} />
        </div>
        <div style={{ marginTop: 10 }}>
          Alternative ID<br />
          <input type="text" {...device_altid} />
        </div>
        <div style={{ marginTop: 10 }}>
          System Firmware<br />
          <input type="text" {...device_sysfw} />
        </div>
        <div style={{ marginTop: 10 }}>
          Active<br />
          <select name="device_active" {...device_active}>
            <option value="true">Active</option>
            <option value="false">Inactive</option>
          </select>
        </div>
        {error && <><small style={{ color: 'red' }}>{error}</small><br /></>}<br />
        <input type="button" value={create ? 'Create' : 'Modify'} onClick={handleUpdate} disabled={updating} /><br />
      </div>
    );
  }
  else {
    return(
      <HandleRejection open={true}/>
    );
  }
}

const useFormInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

const useSelectInput = initialValue => {
  const [value, setValue] = useState(initialValue);

  const handleChange = e => {
    setValue(e.target.value);
  }
  return {
    value,
    onChange: handleChange
  }
}

export default Device;
